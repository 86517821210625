<button class="close-icon" mat-icon-button (click)="closeDialog()">
  <mat-icon class="button-block_small-icon" svgIcon="close" />
</button>

<div class="d-flex justify-content-center mb-1">
  <button mat-icon-button [matTooltip]="isVerticalLayout ? 'Landscape' : 'Portrait'" (click)="toggleMobileView()">
    <mat-icon svgIcon="phone-2" [ngClass]="{ rotate: isVerticalLayout }" />
  </button>
</div>

<div class="phone" [ngClass]="{ phone_portrait: isVerticalLayout, phone_landscape: !isVerticalLayout }">
  @if (form()) {
    <div class="phone_content">
      <div class="phone_content_form-name">{{ form().name }}</div>

      <mat-divider />

      @if (availablePages()[paginator.pageIndex]) {
        <phar-form-page
          #formPageContent
          class="phone_content_form-page"
          [builderMode]="false"
          [allowSelectEditMode]="false"
          [page]="availablePages()[paginator.pageIndex]"
          [form]="form()"
          [isCommentsVisible]="false"
          [isVerticalLayout]="isVerticalLayout" />
      }

      <mat-divider />

      <mat-toolbar class="bg-transparent">
        <h5>{{ availablePages()[paginator.pageIndex]?.title }}</h5>
        <div class="ms-auto">Page {{ paginator.pageIndex + 1 }} of {{ paginator.length }}</div>
        <mat-paginator
          #paginator
          [hidePageSize]="true"
          [length]="availablePages().length"
          [pageSize]="1"
          (page)="scrollToTop()">
        </mat-paginator>
      </mat-toolbar>
    </div>
  }
</div>
