<div class="container pb-5 pt-4 px-3">
  <form id="workspace-form" class="px-0 mx-0" [formGroup]="form">
    <div class="row">
      <div class="col-10">
        <mat-label class="required-field"
          >Form type
          <phar-help-icon
            helpText="ePRO: Patient Reported Outcomes
          eClinRO: Clinician Reported Outcomes" />
        </mat-label>
        <mat-form-field>
          <mat-select placeholder="Type" formControlName="type">
            @for (formType of formTypes; track $index) {
              <mat-option [value]="formType.type">
                <span>{{ formType.name }}</span>
              </mat-option>
            }
          </mat-select>
          <mat-error> Form type is <strong>required</strong> </mat-error>
        </mat-form-field>
      </div>

      <div class="col-2">
        <mat-label class="required-field">Version</mat-label>
        <mat-form-field>
          <input type="text" matInput formControlName="version" placeholder="Version" />
          <mat-error>
            @if (form.get('version').hasError('required')) {
              Version is <strong>required</strong>
            }
            @if (form.get('version').hasError('pattern')) {
              The Version must be in the following format <strong>x.x.x</strong>
            } @else if (form.get('version').hasError('minVersion')) {
              The version cannot be lower than <strong>{{ MIN_NEW_FORM_VERSION }}</strong> or <strong>current</strong>
            }
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <mat-label class="required-field">Form name</mat-label>
    <mat-form-field>
      <input
        matInput
        pharInputTrim
        formControlName="name"
        placeholder="Name"
        [maxlength]="250"
        [pharMaxLengthTooltip]="250" />

      <mat-error>
        @if (form.get('name').hasError('required')) {
          Form name is <strong>required</strong>
        }
      </mat-error>
      <mat-error>
        @if (form.get('name').hasError('maxlength')) {
          Form name maximum length is <strong>250</strong>
        }
      </mat-error>
    </mat-form-field>

    <mat-label
      >Form description
      <phar-help-icon
        helpText="This field is optional and can be used to further clarify form information or goal of the form." />
    </mat-label>
    <mat-form-field>
      <textarea
        matInput
        cdkTextareaAutosize
        formControlName="description"
        placeholder="Description"
        cdkAutosizeMaxRows="20"
        cdkAutosizeMinRows="1"
        [maxlength]="500"
        [pharMaxLengthTooltip]="500">
      </textarea>

      <mat-error> Form description max length is <strong>500</strong> </mat-error>
    </mat-form-field>

    <mat-label class="required-field">Language</mat-label>
    <mat-form-field class="w-100">
      <phar-autocompleter
        displayValue="name"
        filterValue="name"
        formControlName="languageId"
        returnValue="id"
        placeholder="Select Language"
        [data]="languages$ | async"
        [autoCompleter]="true" />
      <mat-error> Language is <strong>required</strong> </mat-error>
    </mat-form-field>

    <mat-checkbox color="primary" formControlName="isMandatoryToSign">Mandatory to sign </mat-checkbox>

    @if (originalForm$ | async; as originalForm) {
      <div class="d-flex align-center">
        <mat-label>Duplicated from:</mat-label>
        <div
          class="original-form-badge d-flex justify-content-center align-center"
          matTooltipPosition="above"
          [matTooltip]="originalForm.name"
          [matTooltipDisabled]="originalFormName.scrollWidth <= originalFormName.clientWidth"
          (click)="originalFormClick(originalForm)">
          <mat-icon class="original-form-badge_icon" svgIcon="duplicate" />
          <span #originalFormName class="original-form-badge_form-name">
            {{ originalForm.name }}
          </span>
        </div>
      </div>
    }

    @if (amendForm$ | async; as amendForm) {
      <div class="d-flex align-center mt-3">
        <mat-label>First parent:</mat-label>
        <div
          class="amend-form-badge d-flex justify-content-center align-center"
          matTooltipPosition="above"
          [matTooltip]="amendForm.name"
          [matTooltipDisabled]="amendFormName.scrollWidth <= amendFormName.clientWidth"
          (click)="originalFormClick(amendForm)">
          <mat-icon class="amend-form-badge_icon" svgIcon="change" />
          <span #amendFormName class="amend-form-badge_form-name">
            {{ amendForm.name }}
          </span>
        </div>
      </div>
    }

    <mat-toolbar class="background-transparent p-0 px-3">
      @if (currentForm$ | async; as currentForm) {
        @if (currentForm.formStatus === formStatusEnum.Rejected || currentForm.formStatus === formStatusEnum.Amended) {
          <button
            *ngxPermissionsOnly="PermissionsEnum.ChangeStatusECOAReturnToDraft"
            mat-raised-button
            class="btn-small button-success"
            [disabled]="currentForm.isArchived"
            (click)="returnToDraftAndUnlockForm(currentForm)">
            <mat-icon iconPositionEnd svgIcon="change" class="mr-2" />
            <b>Return to Draft</b>
          </button>
        }
      }

      <phar-button
        class="ms-auto btn-small"
        [type]="'raised'"
        [state]="'success'"
        [disabled]="!!(hasPendingChanges$ | async) === false || (globalLoading$ | async)"
        (btnClicked)="addEditForm()">
        <mat-icon [svgIcon]="isNewForm ? 'plus' : 'check'" />
        <b>Save</b>
      </phar-button>
    </mat-toolbar>
  </form>
</div>
