import { ActivatedRouteSnapshot, mapToCanDeactivate, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { NewFormComponent } from './new-form/new-form.component';
import { ListFormComponent } from './list-form/list-form.component';
import { FormEditInContextComponent } from './form-edit-in-context/form-edit-in-context.component';
import { FormDetailsComponent } from './form-details/form-details.component';
import { FormContentComponent } from './form-content/form-content.component';
import { FormContextComponent } from './form-context/form-context.component';
import { ExportComponent } from '../shared/export/export.component';
import { PendingChangesGuard } from '../shared/guards/pending-changes.guard';
import { FormApprovalsComponent } from './form-approvals/form-approvals.component';
import { ngxPermissionsGuard } from 'ngx-permissions';
import { PermissionsEnum } from '../permission/permissions.enum';

// const redirectToFunc = (rejectedPermissionName: string, activateRouteSnapshot: ActivatedRouteSnapshot) => {
//   switch (rejectedPermissionName) {
//     case 'FormSettings':
//       const pathFromRoot = activateRouteSnapshot.pathFromRoot.slice(0, activateRouteSnapshot.pathFromRoot.length - 1);
//       let url = '/';
//       for (const path of pathFromRoot) {
//         for (const urlPath of path.url) {
//           if (urlPath) {
//             url += urlPath + '/';
//           }
//         }
//       }
//       url += 'details';
//       return url;
//     default:
//       return '';
//   }
// };

/**
 * form routes
 */
const routes: Routes = [
  {
    path: '',
    children: [
      { path: '', redirectTo: 'list', pathMatch: 'prefix' },
      { path: 'list', component: ListFormComponent },
      {
        path: 'edit/:formId',
        component: FormContextComponent,
        canActivate: [ngxPermissionsGuard],
        data: {
          noReuseParamsChange: true,
          permissions: {
            only: (route: ActivatedRouteSnapshot) => {
              return route.data.isReleasedOnly
                ? [PermissionsEnum.ReadECOALibrary]
                : [PermissionsEnum.EditECOA, PermissionsEnum.ReadECOA];
            },
          },
        },
        children: [
          { path: '', redirectTo: 'details', pathMatch: 'prefix' },

          {
            path: 'details',
            component: FormDetailsComponent,
            canDeactivate: mapToCanDeactivate([PendingChangesGuard]),
          },

          {
            path: 'content',
            component: FormContentComponent,
            canDeactivate: mapToCanDeactivate([PendingChangesGuard]),
          },
          // {
          //   path: 'settings',
          //   component: FormSettingsComponent,
          //   canActivate: [ngxPermissionsGuard],
          //   canDeactivate: mapToCanDeactivate([PendingChangesGuard]),
          //   data: {
          //     permissions: {
          //       only: 'FormSettings',
          //       redirectTo: redirectToFunc
          //     }
          //   }
          // },
          {
            path: 'export-form',
            component: ExportComponent,
            canActivate: [ngxPermissionsGuard],
            data: {
              permissions: {
                only: PermissionsEnum.ExportECOA,
              },
            },
          },
          {
            path: 'approvals',
            component: FormApprovalsComponent,
          },
        ],
      },
      {
        path: 'edit-context/:formId',
        component: FormEditInContextComponent,
        canActivate: [ngxPermissionsGuard],
        data: {
          permissions: {
            only: PermissionsEnum.EditECOA,
          },
        },
        children: [
          { path: '', redirectTo: 'details', pathMatch: 'prefix' },
          {
            path: 'details',
            component: FormDetailsComponent,
            data: { isContext: true },
            canDeactivate: mapToCanDeactivate([PendingChangesGuard]),
          },
          // {
          //   path: 'settings',
          //   component: FormSettingsComponent,
          //   canActivate: [ngxPermissionsGuard],
          //   canDeactivate: mapToCanDeactivate([PendingChangesGuard]),
          //   data: {
          //     permissions: {
          //       only: 'FormSettings',
          //       redirectTo: redirectToFunc
          //     }
          //   }
          // },
          {
            path: 'content',
            component: FormContentComponent,
            canDeactivate: mapToCanDeactivate([PendingChangesGuard]),
          },
          {
            path: 'export-form',
            component: ExportComponent,
          },
        ],
      },
      {
        path: 'new',
        component: NewFormComponent,
        canActivate: [ngxPermissionsGuard],
        data: {
          permissions: {
            only: PermissionsEnum.CreateECOA,
          },
        },
        children: [
          { path: '', redirectTo: 'details', pathMatch: 'prefix' },
          {
            path: 'details',
            component: FormDetailsComponent,
            data: { isNewForm: true },
            canDeactivate: mapToCanDeactivate([PendingChangesGuard]),
          },
        ],
      },
    ],
  },
];

/**
 * This module is responsible only for form routes
 */
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FormRoutingModule {}
