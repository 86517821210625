import { Component, Inject, OnInit } from '@angular/core';
import { MatButton, MatIconButton } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { AsyncPipe } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/models/app.state';
import { Observable } from 'rxjs';
import { selectAvailableFormPages } from '../../form/store/form.state';
import { map } from 'rxjs/operators';
import { IDuplicateDialogData } from './duplicate-dialog-data.interface';
import { IFormPage } from '../../form/form.model';

export enum CopyTargetEnum {
  CurrentPage,
  AnotherPage,
}

@Component({
  selector: 'phar-duplicate-dialog',
  templateUrl: 'duplicate-dialog.component.html',
  styleUrl: 'duplicate-dialog.component.scss',
  standalone: true,
  imports: [
    MatButton,
    MatDialogActions,
    MatDialogContent,
    MatDialogTitle,
    MatIcon,
    MatIconButton,
    SharedModule,
    AsyncPipe,
  ],
})
export class DuplicateDialogComponent implements OnInit {
  copyTargetFormCtrl: FormControl<number> = new FormControl<number>(CopyTargetEnum.CurrentPage);
  selectedPageFormCtrl: FormControl<number> = new FormControl<number | null>(null);
  targetOptions: { id: number; label: string }[] = [
    { id: CopyTargetEnum.CurrentPage, label: 'Current Page' },
    { id: CopyTargetEnum.AnotherPage, label: 'Another Page' },
  ];
  CopyTargetEnum = CopyTargetEnum;

  pages$: Observable<{ index: number; title: string }[]>;

  constructor(
    private store: Store<AppState>,
    public dialogRef: MatDialogRef<DuplicateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDuplicateDialogData,
  ) {}

  ngOnInit() {
    this.selectedPageFormCtrl.setValue(this.data.currentPageIndex);
    // AvailableFormPages are those that are in the paginator
    this.pages$ = this.store.select(selectAvailableFormPages).pipe(
      map((pages: IFormPage[]) => {
        return pages.map((page, index: number) => ({ index: index, title: page.title }));
      }),
    );
    if (this.data.mode === 'move') {
      this.copyTargetFormCtrl.setValue(CopyTargetEnum.AnotherPage);
    }
  }

  close(): void {
    this.dialogRef.close(null);
  }

  handleAction() {
    if (this.data.mode === 'move' && this.data.currentPageIndex === this.selectedPageFormCtrl.value) {
      this.close();
      return;
    }
    this.dialogRef.close({ pageIndex: this.selectedPageFormCtrl.value });
  }
}
